/** @jsx jsx */

import { useRef, useCallback, Fragment } from "react"
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  Flex,
  Stack,
  useDisclosure,
  Grid,
} from "@chakra-ui/core"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import { jsx, css } from "@emotion/core"
import { BiCheckCircle, BiNotepad, BiMoney } from "react-icons/bi"
import { AiFillAppstore, AiOutlineCalculator } from "react-icons/ai"
import { MdDirectionsRailway } from "react-icons/md"

import Layout from "../components/Layout"
import NewsPreview from "../components/NewsPreview"
import LatestNewsItem from "../components/LatestNewsItem"
import ClientListItem from "../components/ClientListItem"
import TeamMemberItem from "../components/TeamMemberItem"
import HowWeWorkStep from "../components/HowWeWorkStep"
import CasesItem from "../components/CasesItem"
import ServicesItem from "../components/ServicesItem"
import ScrollerButton from "../components/chakraOverrides/ScrollerButton"
import LightHoverButton from "../components/chakraOverrides/LightHoverButton"
import makeBackgroundImageArray from "../helpers/makeBackgroundImageArray"

const ClientsScrollByHowMuch = {
  x: 200,
  y: 0,
}

const steps = [
  <BiNotepad />,
  <AiOutlineCalculator />,
  <BiCheckCircle />,
  <AiFillAppstore />,
  <BiMoney />,
  <MdDirectionsRailway />,
]

export default props => {
  const clientsListRef = useRef()
  const casesListRef = useRef()
  const { onOpen, isOpen, onClose } = useDisclosure()
  const scrollCases = useCallback(
    direction => {
      const widthOfCase = casesListRef.current.children[0].getBoundingClientRect()
        .width
      casesListRef.current.scrollBy(
        direction === "right" ? widthOfCase : -widthOfCase,
        0
      )
    },
    [casesListRef]
  )
  const intl = useIntl()
  return (
    <Fragment>
      <Layout
        location={props.location}
        bg={makeBackgroundImageArray("home")}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <Box pt={12} maxW="800px" my={20}>
          <Heading fontSize="6xl" as="h1" fontWeight="bold">
            <FormattedMessage id="homepage.heading" />
          </Heading>
          <Text fontSize="lg" my={3} maxW="600px">
            <FormattedMessage id="homepage.subheading_1" />
          </Text>
        </Box>
        <Box my={8}>
          <Heading fontSize="4xl" fontWeight="bold" as="h2">
            <FormattedMessage id="homepage.what_we_provide" />
          </Heading>
          <SimpleGrid
            columns={[1, null, 2, 3]}
            spacing={4}
            my={4}
            alignItems="center"
          >
            {[MdDirectionsRailway, "person", "searchbox", "rotatebox", "corn"].map(
              (icon, num) => (
                <ServicesItem
                  icon={icon}
                  text={intl.formatMessage({
                    id: `homepage.services.${num}.title`,
                  })}
                  description={intl.formatHTMLMessage({
                    id: `homepage.services.${num}.description`,
                  })}
                  placeOrderText={intl.formatMessage({
                    id: `homepage.place_order`,
                  })}
                  onPlaceOrder={onOpen}
                />
              )
            )}
            <LightHoverButton onClick={onOpen}>
              {intl.formatMessage({ id: "homepage.place_order" })}
            </LightHoverButton>
          </SimpleGrid>
        </Box>
        <Box my={8}>
          <Heading fontSize="4xl" fontWeight="bold" as="h2" my={5}>
            <FormattedMessage id="homepage.news" />
          </Heading>
          <Flex
            direction="row"
            wrap={["wrap", null, null, "nowrap"]}
            justifyContent="space-between"
          >
            <Stack mr={4} alignItems="flex-start">
              {props.data.fullPosts.edges
                .map(edge => edge.node)
                .map(node => (
                  <NewsPreview
                    author={node.creator}
                    headline={node.title}
                    excerpt={node.content}
                    slug={node.link}
                  />
                ))}
            </Stack>
            <Box
              minW={300}
              maxW={["100%", null, null, "25%"]}
              w="100%"
              my={[4, null, null, 0]}
              p={4}
              border="7px solid white"
            >
              <Heading fontSize="2xl" fontWeight="bold" mb={3}>
                <FormattedMessage id="homepage.latest_news" />
              </Heading>
              <Stack spacing={2} w="100%">
                {props.data.smallPosts.edges
                  .map(edge => edge.node)
                  .map(node => (
                    <LatestNewsItem
                      text={node.title}
                      slug={node.link}
                      date={new Date(node.pubDate).toLocaleDateString()}
                    />
                  ))}
              </Stack>
              <LightHoverButton as={Link} to="/news" width="100%" my={4}>
                <FormattedMessage id="homepage.all_news" />
              </LightHoverButton>
            </Box>
          </Flex>
        </Box>
        <Box my={8} border="7px solid white" p={4}>
          <Heading fontSize="2xl" fontWeight="bold" mb={3}>
            <FormattedMessage id="homepage.partners" />
          </Heading>
          <Flex alignItems="center">
            <ScrollerButton
              display={["none", "inline-flex"]}
              icon="chevron-left"
              onClick={() =>
                clientsListRef.current.scrollBy(
                  -ClientsScrollByHowMuch.x,
                  ClientsScrollByHowMuch.y
                )
              }
            />
            <Stack
              isInline
              overflowX="auto"
              alignItems="stretch"
              mx={3}
              py={2}
              ref={clientsListRef}
              css={css`
                scroll-behavior: smooth;
              `}
              role="list"
            >
              <ClientListItem
                name="ТОВ «Транссервіс – М»"
                image={props.data.tcm.childImageSharp.fixed}
                href="http://ts-m.com.ua/page/page17.html"
              />
              <ClientListItem
                name="ТОВ «ЗЕРНОЕКСПОРТ 1»"
                image={props.data.threee1.childImageSharp.fixed}
              />
              <ClientListItem
                name="АТ «Укрзалізниця»"
                image={props.data.y3.childImageSharp.fixed}
                href="https://www.uz.gov.ua/"
              />
              <ClientListItem
                name={`Асоціація "Український логістичний альянс"`}
                image={props.data.yna.childImageSharp.fixed}
                href="https://ula-online.org/ua"
              />
              <ClientListItem
                name={`ТОВ "5ПІЕЛЬ"`}
                image={props.data.fiveplandkls.childImageSharp.fixed}
                href="http://5pl.com.ua/"
              />
              <ClientListItem
                name="ТОВ «РЕЙЛ ІНСАЙДЕР»"
                image={props.data.ri.childImageSharp.fixed}
                href="https://www.railinsider.com.ua/"
              />
              <ClientListItem
                name={`ООО "ИНТЕРЛОГИСТИКС"`}
                image={props.data.tn.childImageSharp.fixed}
                href="http://www.inter-logistics.ru/"
              />
            </Stack>
            <ScrollerButton
              display={["none", "inline-flex"]}
              icon="chevron-right"
              onClick={() =>
                clientsListRef.current.scrollBy(
                  ClientsScrollByHowMuch.x,
                  ClientsScrollByHowMuch.y
                )
              }
            />
          </Flex>
        </Box>
        <Box my={8}>
          <Flex my={6}>
            <Heading fontSize="2xl" as="h2">
              <FormattedMessage id="homepage.team" />
            </Heading>
            <Box mx="auto" />
            <Text as={Link} to="/team" fontWeight="bold" color="purple.500">
              <FormattedMessage id="homepage.whole_team" />
            </Text>
          </Flex>
          <SimpleGrid columns={/*[1, 2, 3, null]*/1} spacing={4} my={6} maxW={600} mx="auto">
            <TeamMemberItem
              name={intl.formatMessage({ id: "team.team_positions.ceo.name" })}
              position={intl.formatMessage({
                id: "team.team_positions.ceo.position",
              })}
              description=""
              image={props.data.johanne.childImageSharp.fluid}
            />
          </SimpleGrid>
        </Box>
        <Box my={8}>
          <Heading fontSize="2xl" fontWeight="bold" mb={3}>
            <FormattedMessage id="homepage.how_we_work" />
          </Heading>
          <Text fontSize="lg">
            <FormattedMessage id="homepage.how_we_work_subheading" />
          </Text>
          <Grid
            templateColumns={["1fr", null, "repeat(2, 1fr)", null]}
            alignContent="center"
            justifyItems="center"
            width="100%"
            gap={6}
          >
            {steps.map((step, i) => (
              <FormattedMessage id={`homepage.how_we_work_steps.${i}`}>
                {txt => <HowWeWorkStep number={step} title={txt} />}
              </FormattedMessage>
            ))}
          </Grid>
        </Box>
      </Layout>
    </Fragment>
  )
}

export const query = graphql`
  {
    stockImage: file(relativePath: { eq: "stock.jpg" }) {
      childImageSharp {
        fixed(width: 350, height: 240, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    fiveplandkls: file(relativePath: { eq: "clients/5plandkls.jpg" }) {
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    ri: file(relativePath: { eq: "clients/RI.png" }) {
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    threee1: file(relativePath: { eq: "clients/ЗЕ1 logo.jpg" }) {
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    tcm: file(relativePath: { eq: "clients/ТСМ.jpg" }) {
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    y3: file(relativePath: { eq: "clients/YЗ.png" }) {
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    yna: file(relativePath: { eq: "clients/УЛА.png" }) {
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    tn: file(relativePath: { eq: "clients/ТЛ.png" }) {
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    johanne: file(relativePath: { eq: "levser.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    jf: file(relativePath: { eq: "levser.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 500, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    fullPosts: allFeedRailInsider(
      limit: 4
      sort: { order: DESC, fields: pubDate }
    ) {
      edges {
        node {
          link
          content
          title
          creator
        }
      }
    }
    smallPosts: allFeedRailInsider(
      limit: 5
      sort: { order: DESC, fields: pubDate }
      skip: 4
    ) {
      edges {
        node {
          link
          title
          pubDate
        }
      }
    }
  }
`

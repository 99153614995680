/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { useState } from "react"
import { Box, Heading, Text, PseudoBox } from "@chakra-ui/core"
import Image from "gatsby-image"
import PropTypes from "prop-types"

const TeamMemberItem = ({ name, position, description, image, css: _css, onClick: _onClick, ...props }) => {
  const [isDetailsShown, showDetails] = useState(false)
  return (
    <Box
      css={css`
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
      `}
      onClick={() => showDetails(!isDetailsShown)}
      {...props}
    >
      <PseudoBox
        display="flex"
        flexDir="column"
        bg="#dddddd80"
        gridRow="1 / -1"
        gridColumn="1 / -1"
        zIndex="1"
        opacity={isDetailsShown ? 1 : 0}
        p={6}
        _hover={{ opacity: 1 }}
        transition="opacity 100ms ease"
      >
        <Heading fontSize="xl" my={4}>
          {name}
        </Heading>
        <Text my={4} color="purple.500" fontWeight="bold">
          {position}
        </Text>
        <Text my={4}>{description}</Text>
      </PseudoBox>
      <Image
        fluid={image}
        style={{ gridRow: "1 / -1", gridColumn: "1 / -1", zIndex: 0 }}
      />
    </Box>
  )
}

TeamMemberItem.propTypes = {
  name: PropTypes.string,
  position: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
}

export default TeamMemberItem
